<template>
	<div id="top" class="mt20 ortho-results-container">
		<TestReportComponent
			v-if="testScore && user && testBank"
			:testBank="testBank"
			:user="user"
			:testTracks="testTracks"
			:testScore="testScore"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import TestReportComponent from '@/components/test-report/TestReportComponent.vue';

export default {
	name: 'TestResultPage',
	components: { TestReportComponent },
	data() {
		return {
			testBank: null,
			testTracks: null,
			testScore: null,
			user: null,
		};
	},
	computed: {
		...mapGetters('profile', {
			formationProgress: 'formationProgress',
			allFormationsAndConfigs: 'allFormationsAndConfigs',
		}),
	},
	created() {
		const isAuthenticated = localStorage.getItem('token') != null;
		if (isAuthenticated == false) {
			this.$router.push({ name: 'Login', query: { onSuccessRedirectTo: this.$route.fullPath } });
			return;
		}
	},
	async mounted() {
		this.testScore = await this.$store.dispatch('testScores/fetchTestScoreById', {
			testScoreId: this.$route.params.testScoreId,
		});

		await Promise.all([
			this.fetchAndSetUser(this.testScore.user_id),
			this.fetchAndSetTestTracks(this.testScore.test_bank_id),
			this.fetchTestBank(this.testScore.test_bank_id),
		]);

		this.checkAndUpdateUrlQuery();
	},
	methods: {
		async fetchAndSetTestTracks(testBankId) {
			this.testTracks = await this.$store.dispatch('tests/fetchAndSetTestTracks', { testBankId });
		},
		async fetchAndSetUser(userId) {
			this.user = await this.$store.dispatch('accountManager/getUserWihoutFormation', { idUser: userId });
		},
		async fetchTestBank(testBankId) {
			this.testBank = await this.$store.dispatch('tests/fetchTestBankById', { testBankId });
		},
		async fetchUserFormations() {
			if (localStorage.getItem('token') == null) return null;
			let formations = null;
			if (this.allFormationsAndConfigs == null) {
				formations = await this.$store.dispatch('profile/fetchAllFormationsAndTopic');
			} else {
				formations = this.allFormationsAndConfigs;
			}

			return formations;
		},
		async checkAndUpdateUrlQuery() {
			// Update url query: testHomePageTopicSlugQuery=topic_slug for display right topic NavBar
			const formations = await this.fetchUserFormations();
			if (formations == null || formations.length === 0) return;

			const topicId = this.testScore.topic_id;
			const formation = formations.find((f) => f.config.topic_id._id === topicId);
			if (formation != null && this.formationProgress == null) {
				this.$store.dispatch('profile/formationProgress', { idFormation: formation._id });
				this.$store.dispatch('appState/selectFormationId', { formationId: formation._id });
			}

			if (this.testScore.topic_slug && !this.$route.query.testHomePageTopicSlugQuery) {
				const newQuery = { ...this.$route.query, testHomePageTopicSlugQuery: this.testScore.topic_slug };
				this.$router.replace({ query: newQuery });
			}
		},
	},
};
</script>
<style scoped></style>
